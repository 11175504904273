<template>
  <body>
    <div class="mt-5"></div>
    <div class="privacy-section container">
      <div class="privacy">
        <div class="privacy">
          <div class="detail">
            <h1>Terms & Conditions</h1>
            <pre>
By using this site you are bound by these Terms of service and to use the site in accordance with these terms and conditions. our Privacy Policy and any additional terms and conditions that may apply to specific sections of the site or to our services available through the site or otherwise from Hayche.


MEMBERS SIGNUP
	
To use certain features of the Site, you will need a username and password, which you will receive through our registration process. You are responsible for maintaining the confidentiality of the password and account, and are responsible for all activities (whether by you or by others) that occur under your password or account. You agree to notify us immediately of any unauthorized use of your password or account or any other breach of security, and to ensure that you exit from your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to protect your password or account information.
	
	
MODIFICATION OF SERVICE
	
We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, our site and services (or any part thereof) with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension, or discontinuance of our site or services for any reason.

	
TERMINATION
	
We may cancel or terminate your right to use the site or any part of the site at any time without notice. In the event of cancellation or termination, you are no longer authorized to access the part of the site affected by such cancellation or termination. The restrictions imposed on you with respect to materials downloaded from the site, and the disclaimers and limitations of liabilities set forth in these Terms of Service, shall survive.
	
	

FEEDBACK
	
We welcome and encourage you to provide feedback, comments, and suggestions for improvements to the Platform. You acknowledge and agree that any and all feedback provided by way of the Platform or otherwise will be the sole and exclusive property of Hayche, and you hereby irrevocably assign to Hayche and agree to irrevocably assign to Hayche all of your right, title, and interest in and to all feedback, including without limitation all worldwide patent rights, copyright rights, trade secret rights, and other proprietary or intellectual property rights therein. At our request and expense, you will execute documents and take such further acts as we may reasonably request to assist Hayche to acquire, perfect, and maintain its intellectual property rights and other legal protections for the feedback.
					</pre
            >
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<style scoped>
/* blogs */
.privacy-section {
  margin-bottom: 200px;
}
.privacy-section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
  color: #1a1a1a;
}

.privacy {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 96px;
  margin-bottom: 79px;
}

.privacy .blog {
  width: 100%;
}

@media (max-width: 768px) {
  .privacy {
    place-items: center;
  }
  .privacy .blog {
    width: 100%;
  }
}

.privacy .blog .detail {
  width: 100%;
  height: 100%;
  padding-right: 25px;
}

.privacy .blog img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  margin-bottom: 35px;
}
.privacy .blog span {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #1c1c1c;
  margin-bottom: 23px;
}

.privacy-section h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #615d5d;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
  color: black;
}
/* end of blogs */
</style>